import React, { useState, useEffect, useContext } from "react";

import { BopContext } from "../BopContext";

const ScheduleView = () => {
  const {
    token,
    userId,
    events,
    setEvents,
    selectedDate,
    dateFilteredEvents,
    setDateFilteredEvents,
    showAllDates,
    users,
    setShowEditEvent,
    showEventDetails,
    setShowEventDetails,
  } = useContext(BopContext);

  const [bobInProgress, setBobInProgress] = useState(false);

  useEffect(() => {
    if (!events) return;
    if (showAllDates) return setDateFilteredEvents(events);

    setDateFilteredEvents(
      events
        .filter(
          (event) =>
            formatDate(new Date(event.startTime)) === formatDate(selectedDate)
        )
        .sort((a, b) => new Date(a.startTime) - new Date(b.startTime))
    );
  }, [events, selectedDate, showAllDates]);


  const formatTime = (date) => {
    const d = new Date(date);
    let hours = d.getHours().toString().padStart(2, "0");
    let minutes = d.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const handleClickBob = (eventId, hasLiked) => {
    setBobInProgress(eventId);
    bobEvent(eventId, hasLiked).then((result) => {
      setBobInProgress(false);
      if (result.success) {
        setEvents((currentEvents) =>
          currentEvents.map((event) => {
            if (event._id === eventId) {
              return {
                ...event,
                likes: hasLiked
                  ? event.likes.filter((likeId) => likeId !== userId) // Remove like
                  : [...event.likes, userId], // Add like
              };
            }
            return event;
          })
        );
      } else {
        console.error("Bob/unbob failed:", result.message);
      }
    });
  };

  const bobEvent = async (eventId, hasLiked) => {
    let url = hasLiked
      ? `${process.env.REACT_APP_API_URL}/events/${eventId}/unlike`
      : `${process.env.REACT_APP_API_URL}/events/${eventId}/like`;

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        return { success: false, message: "" };
      }

      const data = await response.json(); // If response includes data
      return { success: true, data: data };
    } catch (error) {
      return { success: false, message: error };
    }
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/events/${eventId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setEvents(events.filter((event) => event._id !== eventId));
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };

  const Event = ({ event }) => {
    const hasLiked = event.likes.includes(userId);

    const likers = event.likes.map((like) => {
      const user = users.find((user) => user._id === like);
      return user ? user.username : "Unknown"; // Provide a default value ('Unknown') if user not found or has no 'username'
    });

    return (
      <li key={event._id} className="mb-4 flex justify-between">
        <div>
          <p
            className="cursor-pointer"
            onClick={() =>
              setShowEventDetails((sEvent) => {
                return sEvent === event._id ? null : event._id;
              })
            }
          >
            <span className="font-bold">{event.name}</span>
          </p>
          <p className="text-sm">{event.location.name.split(",")[0]}</p>
          <p className="text-sm">
            {showAllDates && formatDate(new Date(event.startTime))}{" "}
            {formatTime(event.startTime)} - {formatTime(event.endTime)}
          </p>

          {showEventDetails === event._id && (
            <>
              {event.link && (
                <button className="bg-black text-white mt-2 py-0 px-3 rounded-full mr-2">
                  <a href={event.link} target="_blank">
                    <span className="text-xs">Link</span>
                  </a>
                </button>
              )}
              <button
                className="bg-black text-white mt-2 py-0 px-3 rounded-full mr-2"
                onClick={() => setShowEditEvent(event._id)}
              >
                <span className="text-xs">Edit</span>
              </button>
              <button
                className="bg-black text-white mt-2 py-0 px-3 rounded-full"
                onClick={() => handleDeleteEvent(event._id)}
              >
                <span className="text-xs">Delete</span>
              </button>
            </>
          )}
        </div>
        <div className="ml-2">
          <button
            className={bobInProgress === event._id ? "bg-alernative-accent py-0 px-3 rounded-full" : "bg-accent py-0 px-3 rounded-full"}
            onClick={() => handleClickBob(event._id, hasLiked)}
          >
            <span className="text-xs">
              {likers.length > 0
                ? likers.map((l) => l.substring(0, 1) + " ")
                : 0}
            </span>
          </button>
        </div>
      </li>
    );
  };

  return (
    <div id="events-container">
      <ul className="p-4">
        {dateFilteredEvents.map((event, i) => (
          <Event key={event._id} event={event} />
        ))}
      </ul>
      {dateFilteredEvents.length === 0 && (
        <p className="px-4">No events this day :------(</p>
      )}
    </div>
  );
};

export default ScheduleView;
