import React, { createContext, useState, useEffect } from "react";

export const BopContext = createContext();

export const BopProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("userToken"));
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showAllDates, setShowAllDates] = useState(false);
  const [dateFilteredEvents, setDateFilteredEvents] = useState([]);

  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [showEditEvent, setShowEditEvent] = useState(false);
  const [showEventDetails, setShowEventDetails] = useState();

  const [showHotdog, setShowHotdog] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    localStorage.removeItem('userId');
    setToken(null);
    setUserId(null);
  };


  return (
    <BopContext.Provider
      value={{
        token,
        setToken,
        users,
        setUsers,
        userId,
        setUserId,
        showCreateEvent,
        setShowCreateEvent,
        showEditEvent,
        setShowEditEvent,
        events,
        setEvents,
        selectedDate,
        setSelectedDate,
        dateFilteredEvents,
        setDateFilteredEvents,
        showAllDates,
        setShowAllDates,
        showHotdog,
        setShowHotdog,
        showEventDetails,
        setShowEventDetails,
        handleLogout
      }}
    >
      {children}
    </BopContext.Provider>
  );
};
