import React, { useState, useContext } from "react";
import axios from "axios";

import { BopContext } from "../BopContext";

const LoginPage = () => {
  const { setToken, setUserId } = useContext(BopContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
        username,
        password,
      });
      onLogin(response.data);
    } catch (err) {
      setError("Failed to login");
      console.error(err);
    }
  };

  const onLogin = (loginData) => {
    const token = loginData.token;
    const userId = loginData.userId;
    localStorage.setItem("userToken", token);
    localStorage.setItem("userId", userId);
    setToken(token);
    setUserId(userId);
  };

  return (
    <div className="p-4 h-100vh" id="main-container">
      <h2 className="my-4">Welcome bob!</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            placeholder={"username"}
          />
        </div>
        <div className="mb-4">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder={"password"}
          />
        </div>
        <button type="submit" className="block rounded-full bg-secondary p-4 px-8">Login</button>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
};

export default LoginPage;
