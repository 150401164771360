import React, { useEffect, useContext } from "react";

import { BopContext } from "./BopContext";

import EventsList from "./components/EventsList";
import CreateEvent from "./components/CreateEvent";
import EditEvent from "./components/EditEvent";
import Hotdog from "./components/Hotdog";

const BopInterface = () => {
  const {
    showCreateEvent,
    setShowCreateEvent,
    showEditEvent,
    setUsers,
    showHotdog,
  } = useContext(BopContext);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users`);
    const data = await response.json();
    setUsers(data);
  };

  const toggleCreateEvent = () => {
    setShowCreateEvent((prevCheck) => !prevCheck);
  };

  return (
    <div id="main-container" className="relative">
      {showHotdog && (
        <div id="hotdog-container">
          <Hotdog modelPath="/hotdog/scene.gltf" />
        </div>
      )}
      <EventsList />

      {showCreateEvent && <CreateEvent />}
      {showEditEvent && <EditEvent />}

      <div
        id="new-event-button" 
        className="bg-accent w-12 h-12 cursor-pointer rounded-full flex justify-around items-center border-solid border-2 border-pink absolute z-5"
        onClick={toggleCreateEvent}
      >
        <div className="text-xs text-center">new</div>
      </div>
    </div>
  );
};

export default BopInterface;
