import React, { useState, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BopContext } from "../BopContext";

const EditEvent = () => {
  const { token, events, setEvents, showEditEvent, setShowEditEvent } = useContext(BopContext);

  const event = events.find(e => e._id === showEditEvent);

  const [eventName, setEventName] = useState(event.name);
  const [eventLink, setEventLink] = useState(event.link);
  const [startDateTime, setStartDateTime] = useState(new Date(event.startTime));
  const [endDateTime, setEndDateTime] = useState(new Date(event.endTime));

  const handleEventNameChange = (e) => {
    setEventName(e.target.value);
  };

  const handleEventLinkChange = (e) => {
    setEventLink(e.target.value);
  };

  const handleDateChange = (date, setDate, isStartDate) => {
    setDate((prevDateTime) => {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        prevDateTime.getHours(),
        prevDateTime.getMinutes()
      );
    });

    if (isStartDate) {
      setEndDateTime(
        (prevDateTime) =>
          new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            prevDateTime.getHours(),
            prevDateTime.getMinutes()
          )
      );
    }
  };

  const handleTimeChange = (time, setDate) => {
    setDate(
      (prevDateTime) =>
        new Date(
          prevDateTime.getFullYear(),
          prevDateTime.getMonth(),
          prevDateTime.getDate(),
          time.getHours(),
          time.getMinutes()
        )
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: eventName,
      startTime: startDateTime,
      endTime: endDateTime,
      link: eventLink
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/events/${event._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const updatedEvent = await response.json();
      setEvents((currentEvents) =>
        currentEvents.map(e => (e._id === updatedEvent._id ? updatedEvent : e))
      );
      setShowEditEvent(false);
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  return (
    <div className="absolute bg-primary w-100vw h-100vh top-0 z-10">
      <form className="p-4" onSubmit={handleSubmit}>
        <div className="flex justify-between">
          <h2 className="font-bold mb-10">Edit Event</h2>
          <p
            className="text-xl cursor-pointer"
            onClick={() => setShowEditEvent(false)}
          >
            ×
          </p>
        </div>
        <div>
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={eventName}
            onChange={handleEventNameChange}
            required
            className="bg-primary block w-full border-0 border-b-2 border-black"
          />
          <br />

          <label>Link</label>
          <input
            type="text"
            name="link"
            value={eventLink}
            onChange={handleEventLinkChange}
            className="bg-primary block w-full border-0 border-b-2 border-black"
          />
          <br />

          <label className="block">Start</label>

          <div className="mt-4">
            <DatePicker
              selected={startDateTime}
              onChange={(date) =>
                handleDateChange(date, setStartDateTime, true)
              }
              minDate={new Date("2024-01-28")}
              maxDate={new Date("2024-02-05")}
              dateFormat="yyyy-MM-dd"
              className="bg-primary w-full border-r-0"
              wrapperClassName="w-3/5 inline-block"
            />

            <DatePicker
              selected={startDateTime}
              onChange={(time) => handleTimeChange(time, setStartDateTime)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="HH:mm"
              className="bg-primary w-full"
              wrapperClassName="w-2/5 inline-block"
            />
          </div>

          <br />

          <label className="block">End</label>
          <div className="flex mt-4">
            <DatePicker
              selected={endDateTime}
              onChange={(date) => handleDateChange(date, setEndDateTime)}
              minDate={new Date("2024-01-28")}
              maxDate={new Date("2024-02-05")}
              dateFormat="yyyy-MM-dd"
              className="bg-primary w-full border-r-0"
              wrapperClassName="w-3/5"
            />

            <DatePicker
              selected={endDateTime}
              onChange={(time) => handleTimeChange(time, setEndDateTime)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="HH:mm"
              className="bg-primary w-full"
              wrapperClassName="w-2/5"
            />
          </div>

          <button type="submit" className="block rounded-full bg-secondary p-4 mt-4 text-xs">
            Update Event
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditEvent;