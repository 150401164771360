import React, { useContext, useEffect, useState } from "react";

import { BopContext } from "../BopContext";

import ScheduleView from "./ScheduleView";
import MapComponent from "./MapView";

const EventsList = () => {
  const { setEvents, selectedDate, setSelectedDate, showAllDates, setShowAllDates, token, handleLogout } = useContext(BopContext);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/events`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      });

      if (!response.ok) {
        console.log(response);

        if(response.status === 401) return handleLogout();
        return;
      }

      const events = await response.json();
      setEvents(events);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };



  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  return (
    <div>
      <div id="date-container" className="flex justify-between">
        <button
          className="text-s p-4"
          onClick={() => setSelectedDate(addDays(selectedDate, -1))}
        >
          ⬅️
        </button>
        <div
          className="p-4 cursor-pointer"
          onClick={() => setShowAllDates((showAllDates) => !showAllDates)}
        >
          { showAllDates ? "All" : formatDate(selectedDate)}
        </div>
        <button
          className="text-s p-4"
          onClick={() => setSelectedDate(addDays(selectedDate, 1))}
        >
          ➡️
        </button>
      </div>
      <ScheduleView />

      <MapComponent />
    </div>
  );
};

export default EventsList;
