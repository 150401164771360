import React, { useEffect, useRef, useState, useContext } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import { BopContext } from "../BopContext";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic3RlbGxha29mb2VkIiwiYSI6ImNscXpqcHI5dzA1azUybHBueGZkbjNhdW8ifQ.hDsAJGsvg0VhAXoTQg6OmQ";

const MapComponent = () => {
  const { dateFilteredEvents, showEventDetails } = useContext(BopContext);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [map, setMap] = useState(null);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) return; // Initialize map only once
    let mapObj = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/stellakofoed/clrf3wdiv00fk01qv7ewy9cjg",
      center: [13.404954, 52.520008],
      zoom: 10,
    });

    mapObj.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      })
    );

    mapObj.on("load", () => {
      mapObj.addSource("events", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });

      mapObj.addLayer({
        id: "events",
        type: "circle",
        source: "events",
        paint: {
          "circle-color": "#b9fc32",
          "circle-stroke-color": "#0000ff",
          "circle-stroke-width": 2,
          "circle-radius": 8,
        },
      });

      mapObj.on("click", "events", (e) => {
        const event = JSON.parse(e.features[0].properties.event);
        const link = `https://maps.google.com/?q=${event.location.name}`;
        new mapboxgl.Popup()
          .setLngLat(e.lngLat)
          .setHTML(
            event.location.name +
              `<br/> <a href="${link}" target="_blank">Open in Google Maps</a>`
          )
          .addTo(mapObj);
      });

      setHasLoaded(true);
      setMap(mapObj);
    });

    return () => mapObj.remove();
  }, []);

  useEffect(() => {
    if (hasLoaded) updateMapData();
  }, [hasLoaded, dateFilteredEvents]);

  useEffect(() => {
    if (hasLoaded) highlightEvent();
  }, [hasLoaded, showEventDetails]);

  const updateMapData = () => {
    const geojson = {
      type: "FeatureCollection",
      features: dateFilteredEvents.map((event) => ({
        type: "Feature",
        properties: {
          event: event,
          id: event._id,
        },
        geometry: {
          type: "Point",
          coordinates: [event.location.lng, event.location.lat],
        },
      })),
    };

    map.getSource("events").setData(geojson);
  };

  const highlightEvent = () => {
    if (!showEventDetails)
      return map.setPaintProperty("events", "circle-color", "#b9fc32");
    
    map.setPaintProperty("events", "circle-color", [
      "match",
      ["get", "id"],
      showEventDetails,
      "#FFC0CB",
      "#b9fc32",
    ]);
  };

  return (
    <div
      ref={mapContainerRef}
      id="map-container"
      className="h-1 border-t-2 border-pink"
    />
  );
};

export default MapComponent;
