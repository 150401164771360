import React, { useRef, useEffect } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const Hotdog = ({ modelPath }) => {
  const mountRef = useRef(null);
  const modelRef = useRef(null); // Reference to the 3D model

  useEffect(() => {
    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      
    );
    const renderer = new THREE.WebGLRenderer({ alpha: true }); // Enable transparent background
    renderer.setSize(
      mountRef.current.clientWidth,
      mountRef.current.clientHeight
    );
    mountRef.current.appendChild(renderer.domElement);

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5); // Soft general lighting
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1); // Stronger, directional light
    directionalLight.position.set(0, 1, 1); // Adjust position as needed
    scene.add(directionalLight);


    // Load 3D model
    const loader = new GLTFLoader();
    loader.load(
      modelPath,
      (gltf) => {
        modelRef.current = gltf.scene; // Store a reference to the model
        modelRef.current.scale.set(0.3, 0.3, 0.3);
        scene.add(gltf.scene);
      },
      undefined,
      (error) => {
        console.error("An error happened", error);
      }
    );

    camera.position.z = 1200;

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      if (modelRef.current) {
        modelRef.current.rotation.y += 0.005; // Rotate the model
        modelRef.current.rotation.x += 0.005;
      }

      renderer.render(scene, camera);
    };
    animate();

    // Clean up
    return () => {
      if(mountRef.current) mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} style={{ width: "100%", height: "100%" }} />;
};

export default Hotdog;
