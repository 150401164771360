import mapbox from "@mapbox/mapbox-sdk";
import geocoding from "@mapbox/mapbox-sdk/services/geocoding";

const baseClient = mapbox({
  accessToken:
    "pk.eyJ1Ijoic3RlbGxha29mb2VkIiwiYSI6ImNscXpqcHI5dzA1azUybHBueGZkbjNhdW8ifQ.hDsAJGsvg0VhAXoTQg6OmQ",
});
const geocodingClient = geocoding(baseClient);

export const geocodeLocation = async (locationName) => {
  try {
    const response = await geocodingClient
      .forwardGeocode({
        query: locationName,
        countries: ['de']
      })
      .send();

    if (response && response.body && response.body.features) {
      return response.body.features.map((feature) => ({
        name: feature.place_name,
        coordinates: feature.center,
      }));
    }
    return [];
  } catch (error) {
    console.error("Error during geocoding:", error);
    return null;
  }
};
