import React, { useEffect, useState, useRef, useContext } from "react";

import DatePicker from "react-datepicker";

import { BopContext } from "../BopContext";

import { geocodeLocation } from "./mapboxGeocoder";

const CreateEvent = () => {
  const { token, setEvents, setShowCreateEvent } = useContext(BopContext);

  const [eventName, setEventName] = useState("");
  const [eventLink, setEventLink] = useState();

  const [locationName, setLocationName] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [geocodeResults, setGeocodeResults] = useState([]);

  const [startDateTime, setStartDateTime] = useState(new Date());
  const [endDateTime, setEndDateTime] = useState(new Date());

  const locationInputRef = useRef();

  useEffect(() => {
    if (!selectedLocation) return;
    setLocationName(selectedLocation.name);
    setGeocodeResults([]);
    locationInputRef.current.value = selectedLocation.name;
  }, [selectedLocation]);

  const handleEventNameChange = (e) => {
    setEventName(e.target.value);
  };

  const handleEventLinkChange = (e) => {
    setEventLink(e.target.value);
  };

  const handleLocationChange = (e) => {
    const locationName = e.target.value;
    setLocationName(locationName);

    geocodeLocation(locationName).then((results) => {
      setGeocodeResults(results);
    });
  };

  const handleDateChange = (date, setDate, isStartDate) => {
    setDate((prevDateTime) => {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        prevDateTime.getHours(),
        prevDateTime.getMinutes()
      );
    });

    if (isStartDate) {
      setEndDateTime(
        (prevDateTime) =>
          new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            prevDateTime.getHours(),
            prevDateTime.getMinutes()
          )
      );
    }
  };

  const handleTimeChange = (time, setDate) => {
    setDate(
      (prevDateTime) =>
        new Date(
          prevDateTime.getFullYear(),
          prevDateTime.getMonth(),
          prevDateTime.getDate(),
          time.getHours(),
          time.getMinutes()
        )
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = {
      name: eventName,
      location: {
        name: locationName,
        lat: selectedLocation.coordinates[1],
        lng: selectedLocation.coordinates[0],
      },
      startTime: startDateTime,
      endTime: endDateTime,
    };

    if(eventLink) data['link'] = eventLink;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/events`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const newEvent = await response.json();
      setEvents((currentEvents) => [...currentEvents, newEvent.event]);
      setShowCreateEvent(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="absolute bg-primary w-100vw min-h-screen top-0 z-10">
      <form className="p-4" onSubmit={handleSubmit}>
        <div className="flex justify-between">
          <h2 className="font-bold mb-10">Add a new event to the BOP system</h2>
          <p
            className="text-xl cursor-pointer"
            onClick={() => setShowCreateEvent(false)}
          >
            ×
          </p>
        </div>
        <div>
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={eventName}
            onChange={handleEventNameChange}
            required
            className="bg-primary block w-full border-0 border-b-2 border-black"
          />
          <br />

          <label>Link</label>
          <input
            type="text"
            name="link"
            value={eventLink}
            onChange={handleEventLinkChange}
            className="bg-primary block w-full border-0 border-b-2 border-black"
          />
          <br />

          <label className="block">Start</label>

          <div className="mt-4">
            <DatePicker
              selected={startDateTime}
              onChange={(date) =>
                handleDateChange(date, setStartDateTime, true)
              }
              minDate={new Date("2024-01-28")}
              maxDate={new Date("2024-02-05")}
              dateFormat="yyyy-MM-dd"
              className="bg-primary w-full border-r-0"
              wrapperClassName="w-3/5 inline-block"
            />

            <DatePicker
              selected={startDateTime}
              onChange={(time) => handleTimeChange(time, setStartDateTime)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="HH:mm"
              className="bg-primary w-full"
              wrapperClassName="w-2/5 inline-block"
            />
          </div>

          <br />

          <label className="block">End</label>
          <div className="flex mt-4">
            <DatePicker
              selected={endDateTime}
              onChange={(date) => handleDateChange(date, setEndDateTime)}
              minDate={new Date("2024-01-28")}
              maxDate={new Date("2024-02-05")}
              dateFormat="yyyy-MM-dd"
              className="bg-primary w-full border-r-0"
              wrapperClassName="w-3/5"
            />

            <DatePicker
              selected={endDateTime}
              onChange={(time) => handleTimeChange(time, setEndDateTime)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="HH:mm"
              className="bg-primary w-full"
              wrapperClassName="w-2/5"
            />
          </div>

          <br />

          <label>Location</label>
          <input
            type="text"
            name="location"
            onChange={handleLocationChange}
            required
            className="bg-primary block w-full border-0 border-b-2 border-black"
            ref={locationInputRef}
          />

          {geocodeResults.length > 0 && (
            <ul>
              {geocodeResults.map((result, index) => (
                <li
                  className="p-3 text-blue cursor-pointer"
                  key={index}
                  onClick={() => setSelectedLocation(result)}
                >
                  {result.name}
                </li>
              ))}
            </ul>
          )}
        </div>

        <br />

        <button type="submit" className="block rounded-full bg-secondary p-4">
          Create Event
        </button>
      </form>
    </div>
  );
};

export default CreateEvent;
