import React, { useContext, useEffect, useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import { BopContext } from './BopContext';
import LoginPage from './components/LoginPage';
import BopInterface from './BopInterface';

const App = () => {
  const { token, setToken, setUserId, setShowHotdog, handleLogout } = useContext(BopContext);

  return (
    <div className="App">
      <header className="bg- text-black flex justify-between p-4">
        <h1 className="text-xs font-semibold pt-2">Das BOP <span onClick={() => setShowHotdog(show => !show)}>System</span></h1>
        <button className="text-xs" onClick={handleLogout}>Logout</button>
      </header>

      {!token ? (
        <LoginPage />
      ) : (
        <BopInterface />
      )}
    </div>
  );
};

export default App;
